<template>
    <div class="content">
        <div class="search-attr">
            <div>
<!--                <a-input v-model="searchParams.userName" size='small' style="width:150px;margin:5px;" placeholder='用户名'></a-input>-->
                <a-select placeholder="层级" style="width: 120px;margin-left: 5px;" @change="handleChange" v-model="searchParams.level" size='small' allowClear >

                  <a-select-option value="1">
                    第一级
                  </a-select-option>
                  <a-select-option value="2" >
                    第二级
                  </a-select-option>

                </a-select>

                 <a-select placeholder='父级' style="width: 120px;margin-left: 5px;" @change="handleChange" v-model="searchParams.levelOfOne" size='small' allowClear>
                  <a-select-option  v-for="msg in levelOfOneList" :key="msg.id">
                      {{msg.menuName}}
                  </a-select-option>
                </a-select>
                <a-button  @click="search" size='small' style="margin-left:10px;">搜索</a-button>
            </div>
             <a-button size='small' style="margin:5px;" @click="openModal({})">创建权限</a-button>
        </div>
        <div class="table-content" style="padding-top:20px;">
            <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>record.id||n">
            <div slot="setup" slot-scope="row">
                <a @click="openModal(row)" style='margin-right:5px'>修改</a>
                <a-popconfirm
                    title="确定要操作吗?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="delMenu(row.id)"
                >
                <a style='margin-left:5px'>删除</a>

                </a-popconfirm>
            </div>
            </a-table>
        </div>


        <a-modal v-model="visible" title="权限" ok-text="确认" cancel-text="取消" @cancel='resetForms' @ok="hideModal">
            <a-form-model
                ref="ruleForm"
                :model="forms"
                :rules="rules"
            >
                <a-form-model-item label="权限名" prop="menuName" >
                    <a-input placeholder='请输入权限名' v-model="forms.menuName"></a-input>
                </a-form-model-item>

                 <a-form-model-item label="路径" prop="menuName" >
                    <a-input placeholder='请输入路径' v-model="forms.menuUrl"></a-input>
                </a-form-model-item>

                <a-form-model-item label="层级" prop="level" >
                    <a-select placeholder="请输入层级" style="width: 120px;" @change="handleChange" v-model="forms.level" size='small' >

                      <a-select-option value="1">
                        第一级
                      </a-select-option>
                      <a-select-option value="2" >
                        第二级
                      </a-select-option>

                    </a-select>

                    </a-form-model-item>

                <a-form-model-item label="父级id" prop="levelOfOne" allowClear placeholder="层级">
                     <a-select style="width: 120px;" @change="handleChange" v-model="forms.levelOfOne" prop="levelOfOne"  size='small'>
                      <a-select-option  v-for="msg in levelOfOneList" :key="msg.id" :value="msg.id">

                          {{msg.menuName}}
                      </a-select-option>
                    </a-select>
                </a-form-model-item>
                
                 <a-form-model-item label="同级排序" prop="weight" >
                    <a-input placeholder='请输入同级排序' v-model="forms.weight"></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-modal>

    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    data(){
        return {
            columns:[
                {title:'ID',dataIndex:'id'},
                {title:'权限名',dataIndex:'menuName'},
                {title:'层级',dataIndex:'level'},
                {title:'父级名',dataIndex:'parentName'},
                {title:'路径',dataIndex:'menuUrl'},
                {title:'同级排序',dataIndex:'weight'},
                {title:'操作',align:'center',scopedSlots: { customRender: 'setup' },},

            ],
            data:[],
            searchParams:{
                pageSize:10,
                pageNum:1,
                // level:'',
                // levelOfOne:"",
            },
            rules:{
                menuName:[{ required: true, message: '不允许为空', trigger: 'blur' }],
                level:[{ required: true, message: '不允许为空', trigger: 'blur' }],
                levelOfOne:[{ required: true, message: '不允许为空', trigger: 'blur' }],
                weight:[{ required: true, message: '不允许为空', trigger: 'blur' }],
                menuUrl:[{ required: true, message: '不允许为空', trigger: 'blur' }],
            },
            times:[],
            forms:{

            },
            visible:false,
            pagination:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            loading:false,
            levelOfOneList :[],
            IO:0,

        }
    },
    computed:{

    },
    watch:{
    },
    created(){
        this.getData(this.searchParams)
        this.getLevelOfOne()
    },
    methods:{
        ...mapActions(['updataMenu']),
        async getLevelOfOne(){
            const res = await this.axios('/dq_admin/menu/list',{params: {level:1}})
            this.levelOfOneList = res.data.records
            this.levelOfOneList.unshift({id:0,menuName:'无父级'})
        },
        async getData(params){
            this.loading = true
            const res = await this.axios('/dq_admin/menu/list',{params:params})
            this.loading = false
            const {records,total} = res.data
            this.data = records
            this.pagination.total = total
        },

        openModal(row){
            this.visible = true
            const id = row&&row.id ? row.id : 0
            this.IO = id ? 1 : 0
            this.forms = id ? JSON.parse(JSON.stringify(row)) : {}
        },
        handleChange(){
            this.getData(this.searchParams)
        },
        search(){
            this.searchParams.pageNum = 1
            this.pagination.current = 1
            this.getData(this.searchParams)
        },
        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.searchParams.pageNum = current
            this.searchParams.pageSize = pageSize
            this.getData(this.searchParams)
        },
        changeTime(result){
            this.times = result
        },
        delMenu(id){
            this.axios('/dq_admin/menu/delMenuById',{params:{id:id}}).then(res=>{
                if (res.status == '200'){
                    this.$message.success(res.message)
                    this.getData(this.searchParams)
                }
            })
        },
        resetForms(){
            this.forms = {}
            this.$refs.ruleForm.resetFields();
        },
        hideModal(){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    this.submit(this.forms)
                }else{
                    return false;
                }
            });
        },
        submit(forms){
            const post_url = this.IO ?  '/dq_admin/menu/add':'/dq_admin/menu/edit'
            this.axios.post(post_url,{...forms}).then(res=>{
                if(res.status == '200'){
                    this.$message.success(res.message)
                    this.visible = false
                    this.getData(this.searchParams)
                }
            })
            this.updataMenu()
            this.getLevelOfOne()
        },
        compoilerTimes(array){
            if(array&&array.length>0){
                array.forEach((ele,index) => {
                    if(index == 0){
                        this.searchParams.startTime = ele._d.toLocaleDateString().replace(/\//g,"-")
                    }else{
                        this.searchParams.endTime = ele._d.toLocaleDateString().replace(/\//g,"-")
                    }
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
</style>